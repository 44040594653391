import React from "react"
import Layout from "../components/layout"
import style from "./terms.module.scss"
const EndUserLicenseAgreement = () => {
  const { wrapper } = style
  return (
    <Layout>
      <div className={wrapper}>
        <h1>End User License Agreement</h1>
        <p>
          End User License Agreement PLEASE READ THE TERMS AND CONDITIONS OF
          THIS LICENSE AGREEMENT CAREFULLY BEFORE USING THE Win Fun APPLICATION
          ("Application"), AND INDICATE YOUR ACCEPTANCE BY CHOOSING “I ACCEPT”.
          THE APPLICATION IS COPYRIGHTED AND LICENSED (NOT SOLD). BY CHOOSING “I
          ACCEPT”, YOU ARE ACCEPTING AND AGREEING TO BE BOUND BY ALL THE TERMS
          OF THIS LICENSE AGREEMENT. THIS LICENSE AGREEMENT IS BETWEEN YOU AND
          EVERYBUDDY GAMES LTD. ("EveryBuddy Games ") ONLY. IF YOU DO NOT AGREE
          TO THIS AGREEMENT DO NOT USE THE APPLICATION.
        </p>
        <p>
          1. License Grant. Subject to the terms herein, EveryBuddy Games hereby
          grants you (“you” or “User”) a limited, personal, non-exclusive,
          non-transferable, non sub-licensable right to use the Application in
          order to play games that include Content imported from Instagram. You
          may share your personalized games as edited by you with the public
          (i.e. identified or un-identified public). The foregoing actions
          enabled by means of the Application shall be hereinafter referred to
          as “Service”. For avoidance of doubt, this license is limited to a
          personal private use and is not intended to permit professional or
          commercial use of the Application. The term "Application" shall
          include all revisions, improvements, new releases and/or updates and
          related documentation all to the extent provided by EveryBuddy Games
          under this Agreement. 2. Restrictions. THE APPLICATION IS LICENSED NOT
          SOLD. Other than the rights explicitly granted in this Agreement, you
          shall have no other rights, express or implied, in the Application.
          Without limiting the generality of the foregoing, you agree and
          undertake not to: (i) sell, lease, sublicense or distribute the
          Application, or any part thereof, or otherwise transfer the
          Application; (ii) reverse engineer, decompile, disassemble, or
          otherwise attempt to access the Application’s source code; (iii)
          modify, revise, enhance, or alter the Application; (iv) copy or allow
          copies of the Application to be made; (v) place the Application onto a
          server accessible for use by any third party; (vi) represent that you
          possess any proprietary interest in the Application; (vii) use the
          Application in any illegal manner or for unlawful purposes. 3.
          Instagram. This Application uses the Instagram API but is not endorsed
          or certified by Instagram. All Instagram™ logos and trademarks
          displayed on this Application, if any, are the property of Instagram.
          4. Content and User Content. For purpose of this Agreement, the term
          "Content" may include, without limitation, video, audio clips, written
          comments, information, data, text and literary works, photos,
          software, graphics, in static or interactive feature, sound
          recordings, personalized games (including slot machines) or any other
          content made available through the Service. The Service enables you to
          choose add, use and distribute certain Content, including, without
          limitation Content that is imported from Instagram (collectively "User
          Content"). EveryBuddy Games does not guarantee that any User Content
          will be made available through the Service. You acknowledge that
          EveryBuddy Games cannot guarantee any anonymity or confidentiality
          with respect to any User Content that it is strongly recommended that
          you carefully choose the User Content that you use. You further
          acknowledge that all User Content is provided for free and is the sole
          responsibility of the person from whom such User Content originated.
          This means that the person who made such Content available through
          Instagram and not EveryBuddy Games , is entirely responsible for all
          such Content that you use or make available through the Service. 5.
          User Content Representations and Warranties. Each User is solely
          responsible for its User Content and the consequences of using or
          publishing it. In connection with User Content, you affirm, represent,
          and warrant that: (i) you comply with the Instagram Terms of Use at
          http://instagram.com/about/legal/terms; (ii) you will not use Content
          that you believe or should reasonably know that (a) infringes or
          misappropriates the rights of any third party, including, without
          limitation, any copyright, trademark, patent, or other intellectual
          property or proprietary right; (b) includes slander, defamation,
          libel, or that invades the right of privacy, publicity or other
          property rights of any other person; (c) violates any applicable law
          or regulation. Whenever you make use of a feature that allows you to
          share or choose Content (including your personalized games ), add
          links, or to interact with other users, you must comply with the
          conditions and privacy policy set forth herein and with those
          published by the social network website upon which such Content is
          shared/displayed (Facebook, Twitter etc 6. Rights in User Content.
          EveryBuddy Games DOES NOT OWN ANY USER CONTENT, the users who made the
          Content available via Instagram own or has license in such Content.
          Access and use of Content that is not yours must comply with the
          owner’s requirements and restrictions. Any Content imported onto the
          Service from Instagram must be ‘publicly available content’ with no
          restrictions on use. In any event you are solely responsible for
          making use of Instagram Content in compliance with the photo owners’
          requirements and restrictions. EveryBuddy Games DOES NOT MONITOR OR
          CONTROL CONTENT IMPORTED FROM INSTAGRAM AND CANNOT BE RESPONSIBLE FOR
          SUCH CONTENT. ANY USE OR RELIANCE ON ANY CONTENT OBTAINED BY YOU
          THROUGH THE SERVICE IS AT YOUR OWN RISK. EveryBuddy Games reserves the
          right to, in its sole discretion, remove, block, edit, move, disable
          or permanently delete User Content from the Service with or without
          notice for any reason whatsoever. 7. License to your User Content. By
          using the Application you hereby grant EveryBuddy Games and its
          affiliates, sublicensees, partners, designees, and assignees of the
          Service a worldwide, non-exclusive, perpetual, irrevocable,
          royalty-free, fully-paid, sub-licensable and transferable license to
          use, edit, modify, reproduce, distribute, host, share, publicly
          perform, publicly display, edit, delete, modify, reformat, translate
          and prepare derivative works based upon User Content owned by you
          (“your User Content”), and/or otherwise exploit your User Content
          including your trademarks and logos included therein in connection
          with the Service and/or EveryBuddy Games ' (and its successors’)
          business, including, without limitation, in connection with marketing
          promoting, and redistributing part or all of the Service (and
          derivative works thereof), in any media formats and through any media
          channels now known or hereafter discovered or developed. You also
          agree to irrevocably waive (and cause to be waived) any claims and
          assertions of so-called "moral rights" or attribution with respect to
          your User Content. 8. Inaccurate or Offensive User Content. You
          understand that when using the Service, you may be exposed to Content
          from a variety of sources, and that EveryBuddy Games does not endorse
          and is not responsible for the accuracy, usefulness, safety, or
          intellectual property rights of, or relating to, such Content. You may
          be exposed to Content that is inaccurate, offensive, indecent or
          objectionable. YOU AGREE TO WAIVE, AND HEREBY DO WAIVE, ANY LEGAL OR
          EQUITABLE RIGHTS OR REMEDIES YOU MAY HAVE AGAINST EveryBuddy Games
          WITH RESPECT THERETO. EveryBuddy Games DOES NOT MONITOR OR CONTROL THE
          CONTENT AND IS NOT RESPONSIBLE FOR IT. 9. Prohibited Uses. You are
          solely responsible for all of your activity in connection with the
          Application and Service. You must comply with all laws and regulations
          that are applicable to your use of this Application. If the Service or
          any part thereof is determined to be illegal under the laws of the
          country in which you are situated, you shall not be granted any
          license to use the Service, and must refrain from using the Service.
          You will not (and shall not permit any third party to) either (a) take
          any action or (b) solicit, use, distribute or facilitate distribution
          of any Content or User Content (as defined above) on or through the
          Service that: (i) is illegal, threatening, abusive, invasive of any
          person's privacy, harassing, defamatory, libelous, false, deceptive,
          fraudulent, misleading, untruthful, tortuous, obscene, offensive,
          pornographic, or otherwise inappropriate, as EveryBuddy Games may
          determine in its sole discretion; (ii) infringes or misappropriates
          any intellectual property rights or proprietary right of any third
          party; violates any right of privacy or right of publicity, of any
          third party ,or (iii) violates applicable laws or the terms of use of
          Instagram; (iv) contains any harmful or malicious Application that are
          designed to damage, interfere with, or limit, the proper functionality
          of the Application, or that enables to obtain unauthorized access to
          the Service; or (vi) impersonates any individual or entity, including,
          without limitation, employees or representatives of EveryBuddy Games .
          10. Your Friends and Contacts. You acknowledge that upon using the
          Service you agree to allow us to access your friends list via the
          social network site where you downloaded the Application. If you
          choose to use your friends’ Content by means of the Service you agree
          that EveryBuddy Games may obtain your friend’s email from your contact
          list or ask that you provide us with your friend's email address. This
          information will be used only to send a one-time message to your
          friend notifying him or her of your request and inviting them to join
          the Service. You agree that EveryBuddy Games may store anonymous
          information provided during the search of your friends subject to the
          terms of our Privacy Policy. 11. Copyright Infringement. We respect
          the intellectual property rights of others and require that those who
          use the Service do the same. If you believe that your proprietary work
          has been infringed by means of the Service in a way that constitutes
          copyright infringement, please please contact us at [insert email].
          Please see our notice and take down procedure at [insert link – see
          below]. 12. Indemnity. UNDER NO CIRCUMSTANCES WHATSOEVER WILL
          EveryBuddy Games , INSTAGRAM OR THE SOCIAL NETWORK SITE WHERE YOU
          ACQUIRED THIS APPLICATION BE LIABLE IN ANY WAY FOR ANY CONTENT,
          INCLUDING, WITHOUT LIMITATION, FOR ANY ERRORS OR OMISSIONS IN ANY
          CONTENT, OR FOR ANY INFRINGEMENT OR MISAPPROPRIATION OF THIRD PARTY'S
          RIGHT, LOSS OR DAMAGE OF ANY KIND INCURRED AS A RESULT OF THE USE OF
          THE CONTENT AND/OR THE APPLICATION. You shall indemnify, defend, and
          hold harmless EveryBuddy Games , and its directors, officers, and
          employees from and against all costs, damages, losses and expenses,
          including reasonable attorneys’ fees and other legal expenses, arising
          from a third-party claim due to or arising out of Content you elect,
          use or disctribute through the Service. EveryBuddy Games shall make
          reasonable efforts to notify you of any such claim made against it.
          13. Title & Ownership. The Application and Service including certain
          Content provided by EveryBuddy Games or its licensors (“EveryBuddy
          Games Content”) are protected under patent and copyright law. The
          Application and Service include valuable trade secrets of EveryBuddy
          Games and any disclosure or unauthorized use thereof will cause
          irreparable harm and loss to EveryBuddy Games . All right, title and
          interest in and to the Application, Service, EveryBuddy Games Content
          and any derivatives thereof, modifications thereto and associated
          intellectual property rights, evidenced by or embodied in and/or
          attached/connected/related thereto are and will remain owned
          exclusively by EveryBuddy Games and/or its licensors. This Agreement
          does not convey to you an interest in or to the Application, Service
          or EveryBuddy Games Content but only a limited right of use in
          accordance with the terms herein. Nothing in this Agreement
          constitutes a waiver of EveryBuddy Games ' or any of its licensors’
          intellectual property rights under any law. 14. Consent to Use of
          Data. By using the Application, you acknowledge that you have reviewed
          and agreed to EveryBuddy Games ’ Privacy Policy [insert link]. YOU
          AGREE THAT EveryBuddy Games MAY COLLECT AND USE PERSONAL INFORMATION
          AS WELL AS NON-PERSONAL INFORMATION SUBJECT TO THE TERMS OF THE
          PRIVACY POLICY. 15. Maintenance and Support. We may provide you with
          support if and as made available to all our Users. 16. Limited
          Warranty. THE APPLICATION AND SERVICE ARE PROVIDED ON AN "AS IS"
          BASIS, WITHOUT WARRANTY OF ANY KIND. TO THE MAXIMUM EXTENT PERMITTED
          BY APPLICABLE LAW, EveryBuddy Games DISCLAIMS ALL WARRANTIES;
          INCLUDING WITHOUT LIMITATION ANY IMPLIED WARRANTIES OF
          MERCHANTABILITY, FITNESS FOR A PARTICULAR PURPOSE, AND
          NONINFRINGEMENT, AND ANY WARRANTIES AND CONDITIONS ARISING OUT OF
          COURSE OF DEALING OR USAGE REGARDING THE APPLICATION OR ANY CONTENT
          YOU DOWNLOAD USING THE APPLICATION. THE ENTIRE RISK ARISING OUT OF THE
          USE OR PERFORMANCE OF THE APPLICATION AND THE CONTENT MADE AVAILABLE
          THROUGH OR ON THE SERVICE REMAINS SOLELY WITH YOU. EveryBuddy Games
          DOES NOT WARRANT THAT THE APPLICATION, CONTENT OR ANY SUPPORT SERVICES
          WILL BE DELIVERED OR PERFORMED (IF PERFORMED) ERROR-FREE OR WITHOUT
          INTERRUPTION. 17. Limitation of Liability. IN NO EVENT SHALL
          EveryBuddy Games ’ LIABILITY UNDER, ARISING OUT OF OR RELATING TO THIS
          AGREEMENT OR USE OF THE APPLICATION EXCEED THE LICENSE FEES PAID BY
          YOU FOR THE APPLICATION, IF ANY, EVEN IF EveryBuddy Games HAS BEEN
          INFORMED OF THE POSSIBILITY OF SUCH DAMAGES, OR FOR ANY CLAIM BY ANY
          OTHER PARTY. SOME JURISDICTIONS DO NOT ALLOW THE EXCLUSION OR
          LIMITATION OF INCIDENTAL OR CONSEQUENTIAL DAMAGES, SO THIS LIMITATION
          AND EXCLUSION MAY NOT APPLY TO YOU. IN NO EVENT WILL EveryBuddy Games
          BE LIABLE FOR LOST PROFITS, LOSS OF USE, LOSS OF CONTENT OR DATA, COST
          OF PROCUREMENT OF SUBSTITUTE GOODS OR SERVICES, OR ANY OTHER SPECIAL,
          INCIDENTAL, INDIRECT, OR CONSEQUENTIAL DAMAGES, HOWEVER CAUSED, AND ON
          ANY THEORY OF LIABILITY, WHETHER FOR BREACH OF CONTRACT, TORT
          (INCLUDING NEGLIGENCE AND STRICT LIABILITY), OR OTHERWISE, WHETHER OR
          NOT EveryBuddy Games HAS BEEN ADVISED OF THE POSSIBILITY OF SUCH
          DAMAGES. 18. Suggestions and Feedback. It is understood that you may,
          at its sole discretion, provide EveryBuddy Games with suggestions
          and/or comments with respect to the Application or Service
          (“Feedback”). You represent that you are free to do so and that you
          shall not knowingly provide EveryBuddy Games with Feedback that
          infringes upon third parties' intellectual property rights. You
          further acknowledge that notwithstanding anything herein to the
          contrary, any and all rights, including intellectual property rights
          in such Feedback shall belong exclusively to EveryBuddy Games and that
          such shall be considered EveryBuddy Games ' Confidential Information.
          It is further understood that use of Feedback, if any, may be made by
          EveryBuddy Games at its sole discretion, and that EveryBuddy Games in
          no way shall be obliged to make use of any kind of the Feedback or
          part thereof. 19. Term and Termination. This Agreement is valid as of
          the date you started using the Application. Without prejudice to any
          other rights, this Agreement and your right to use this Application
          automatically terminates if you fail to comply with any provision of
          this Agreement, the Instagram terms of use or the terms of use that we
          publish through the Application. EveryBuddy Games may terminate this
          Agreement at any time with or without prior notice. You may stop using
          the App and un-install it if you wish to terminate this license. Upon
          termination of this Agreement the license granted to you in this
          Agreement shall expire and you shall cease using the Application and
          remove it from your devices. 20. General. This Agreement including our
          privacy policy and the terms and conditions published through the
          Application, if any, constitute the entire agreement between the
          parties and may not be modified except by a written agreement signed
          by EveryBuddy Games . If a court of competent jurisdiction finds any
          provision of this Agreement to be unenforceable, that provision shall
          be enforced to the maximum extent permissible so as to affect the
          intent of the parties, and the remainder of the Agreement shall
          continue in full force and effect. You may not assign this Agreement
          without EveryBuddy Games ’ prior written approval. EveryBuddy Games
          shall be entitled to assign this Agreement at its discretion. No
          waiver of any breach shall constitute a waiver of any prior,
          concurrent or subsequent breach of the same or any other provision,
          and no waiver shall be effective unless made in writing and signed by
          an authorized representative of the waiving party. This Agreement
          shall be governed by and construed in accordance with the laws of the
          State of New York which state shall have jurisdiction over any dispute
          arising from this Agreement. Sections 2, 8, 12, 13, 17, 18 and 20
          shall survive the termination of this Agreement. The application of
          the United Nations Convention of Contracts for the International Sale
          of Goods is expressly excluded. I HAVE READ AND UNDERSTOOD THIS
          LICENSE AGREEMENT AND AGREE TO BE BOUND BY ALL OF THE TERMS. Please
          indicate your acceptance by clicking 'I ACCEPT' or “REGISTER”.
          Copyright Infringement (Notice and takedown) We respect the
          intellectual property rights of others and require that those who use
          the Service do the same. If you believe that your proprietary work has
          been copied in a way that constitutes copyright infringement, please
          forward the following information to our Copyright Agent, designated
          as such pursuant to the Digital Millennium Copyright Act, 17 U.S.C.
          Section 512(c)(2), named below: 1. A physical signature of the person
          authorized to act on behalf of the owner of the copyright; 2.
          description of the copyrighted work that you claim has been infringed;
          3. A description of where the material that you claim is infringing is
          located on the Service; 4. Your address, telephone number, and email
          address; 5. A statement by you that you have a good faith belief that
          the disputed use is not authorized by the copyright owner, its agent,
          or the law; and 6. A statement by you, made under penalty of perjury,
          that the above information in your notice is accurate and that you are
          the copyright owner or authorized to act on the copyright owner's
          behalf. Copyright Agent: EveryBuddy Games Ltd.
        </p>
      </div>
    </Layout>
  )
}

export default EndUserLicenseAgreement
